import React from 'react';
import PropTypes from 'prop-types';
import { NavBar, NavLink } from 'cccisd-laravel-boilerplate';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const questTabPermissions = [
    'quest.admin',
    'quest.design',
    'quest.deploy',
    'quest.status',
    'quest.code',
    'quest.tables',
    'quest.permissions',
];

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        return (
            <div style={{ marginBottom: '-1.45em' }}>
                <NavBar {...this.props} logo={<div className={style.logo} />}>
                    {Fortress &&
                        Fortress.auth() &&
                        !Fortress.user.acting.is_super_user &&
                        Fortress.hasAnyAccess(questTabPermissions, 'anyTarget') && <NavLink to="/quest">Quest</NavLink>}
                    <li>
                        <a href="https://support.quest4data.com/support" target="_blank" rel="noopener noreferrer">
                            Quest Support
                        </a>
                    </li>
                </NavBar>
            </div>
        );
    }
}
